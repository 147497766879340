import React from "react";

import Layout from "../components/layout"
//import SEO from "../components/SEO"

const IndexPage = () => (
  <Layout>
    
  </Layout>
)

export default IndexPage;
